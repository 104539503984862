import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Layout from "../containers/layout";
import { filterOutDocsWithoutSlugs } from "../lib/helpers";
import ProductsAndServices from "../templates/productsAndServices";

export const query = graphql`
  query ProductsServicesPageQuery {
    sanityProductsServicesSettings {
      title
      description
      keywords
      parallaxImages {
        asset {
          url
          _id
        }
        alt
      }
      parallaxColour {
        hex
      }
      services {
        id
        title
        subtitle
        themeColour {
          hex
        }
        thumbnail {
          asset {
            _id
          }
          alt
        }
        slug {
          current
        }
      }
      products {
        id
        title
        subtitle
        themeColour {
          hex
        }
        thumbnail {
          asset {
            _id
          }
          alt
        }
        slug {
          current
        }
      }
    }
  }
`;

const ProductsServicesPage = (props) => {
  const { data, errors } = props;

  const sanityProductsServicesSettings = (data || {})
    .sanityProductsServicesSettings;
  const serviceNodes = sanityProductsServicesSettings.services
    ? sanityProductsServicesSettings.services.filter(filterOutDocsWithoutSlugs)
    : [];
  const productNodes = sanityProductsServicesSettings.products
    ? sanityProductsServicesSettings.products.filter(filterOutDocsWithoutSlugs)
    : [];

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  return (
    <ProductsAndServices
      sanityProductsServicesSettings={sanityProductsServicesSettings}
      serviceNodes={serviceNodes}
      productNodes={productNodes}
    />
  );
};

export default ProductsServicesPage;
